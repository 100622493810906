<script lang="ts">
    export let options: Option[] | undefined
    export let value: string
    export let required: boolean = false
    export let showErrorMessage: boolean = true
    export let isError: boolean = true
    export let disabled: boolean = false
    export let id: string = ''

    $: isError = required && value == ''
</script>

{#if options}
    <select {id} bind:value class:error={required && value == ''} {disabled}>
        {#if options?.length}
            {#each options as { label, value }}
                <option id={label} {value}>{label}</option>
            {/each}
        {/if}
    </select>
    {#if showErrorMessage && isError && required}
        <span class="error">This field is required.</span>
    {/if}
{/if}

<style lang="scss">
    select {
        border: 1px solid var(--border-color);
        color: var(--color-font);
        background-color: var(--color-white);

        //TODO: Rethink how to handle this properly
        -moz-appearance: auto;
        -webkit-appearance: auto;
        -o-appearance: auto;

        &.error {
            border: 1px solid var(--color-error);
        }
    }

    span {
        &.error {
            margin: 0;
            font-size: small;
            color: var(--color-error);
        }
    }
</style>
