<script lang="ts">
    export let icon: Maybe<string>
</script>

{#if icon}
    <span>
        <i data-icon={icon} />
    </span>
{/if}

<style lang="scss">
    span {
        font-weight: var(--font-weight);
        font-size: var(--font-size);
        line-height: var(--line-height);
        grid-row: 1;
        // transform: translateY(1.5px);

        i {
            display: block;
            font-family: Material Icons Outline;
            font-style: normal;

            letter-spacing: normal;
            white-space: nowrap;
            text-transform: none;
            word-wrap: normal;
            font-feature-settings: 'liga';
            padding-right: 0.8rem;

            // closed
            &::after {
                content: attr(data-icon);

                transform: rotate(0deg);
                transition: all 500ms;
                transform: translate(3px, 2px);
            }
        }
    }
</style>
